<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="doFilter">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field label="Texto" v-model="search"></v-text-field>
              </v-col>
              <v-col cols="9" md="2">
                <v-select
                  :items="tipos"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo Persona"
                  value="5"
                  v-model="tipoSeleccion"
                ></v-select>
              </v-col>
              <v-col cols="3" md="1" class="col-btn-filter">
                <v-btn
                  icon
                  type="submit"
                  color="secondary"
                  dark
                  @click="doFilter(true)"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "PersonasFilter",
  data() {
    return {
      tipos: [],
      search: "",
      tipoSeleccion: 0,
      personas: [],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    VisitasService.getTiposPersona()
      .then((response) => {
        this.tipos = response.data;
        this.tipos.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.loading = true;
      VisitasService.getPersonas(this.search, this.tipoSeleccion, page)
        .then((response) => {
          this.personas = response.data;
          this.$emit("personas-changed", this.personas);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //console.log("haciendo filter con page " + this.pageNumber);
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>
