<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Personas</span>
      </v-col>
      <v-col>
        <v-btn to="/personas/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <PersonasFilter
          @personas-changed="sendPersonas"
          :pageNumber="pageNumber"
        >
        </PersonasFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <PersonasTable
          ref="personaTable"
          :personas="this.personas.results"
          :personasCount="this.personas.count"
          @paginate="handlePagination"
        >
        </PersonasTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PersonasTable from "../components/PersonasTable";
import PersonasFilter from "../components/PersonasFilter.vue";
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "Personas",
  components: {
    PersonasTable,
    PersonasFilter,
  },
  data() {
    return {
      ingresos: null,
      personas: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendPersonas(personas) {
      // this.pageNumber = "1";
      this.personas = personas;
      this.$refs.personaTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
